@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'TG Frekuent Mono';
  src: url('./assets/fonts/TGFrekuentMono-UltraLight.otf');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TG Frekuent Mono';
  src: url('./assets/fonts/TGFrekuentMono-Light.otf');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TG Frekuent Mono';
  src: url('./assets/fonts/TGFrekuentMono-Regular.otf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TG Frekuent Mono';
  src: url('./assets/fonts/TGFrekuentMono-Medium.otf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TG Frekuent Mono';
  src: url('./assets/fonts/TGFrekuentMono-Bold.otf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TG Frekuent Mono';
  src: url('./assets/fonts/TGFrekuentMono-ExtraBold.otf');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TG Frekuent Mono Variable';
  src: url('./assets/fonts/TGFrekuentMono-Variable.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

::-webkit-scrollbar{
  width: 4px;
}

::-webkit-scrollbar-track{
  background: #1E1E1E;
}

::-webkit-scrollbar-thumb{
  background: #848895;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.Toastify__toast {
  --toastify-toast-bd-radius: 0;
  --toastify-toast-min-height: 26px
}

.Toastify__toast-theme--dark{
  --toastify-color-dark: #090909
}